@import "../../../assets/styles/theme";
@import "../../../assets/styles/sidebar";

:root {
  --left: var(--sidebar);
}

[data-device="phone"] {
  --left: 0;
}
[data-device="desctop"] {
  --left: var(--sidebar);
}

.header-wrapper{
  display: flex;
  justify-content: space-between;

  position: fixed;
  top: 0;
  left: var(--left);
  right: 0;
  height: var(--header);
  background-color: $bg;
  z-index: 23;
  padding: .5rem 1rem;
  transition: var(--animation-duration) linear;
  border-bottom: 1px solid #F0F0F0;

  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */

  .left{
    display: flex;
    align-items: center;
    .burger{
      cursor: pointer;

      &:hover{
        color: $blue;
      }
    }
  }

  .right{
    display: flex;
    align-items: center;

    .lang{
      width: 90px;
      height: 32px;
      border-radius: .5rem;
      background-color: $element;
    }
    .profile{
      .name{
        min-height: 32px;
        // border-radius: .5rem;
        // padding: .25rem 1rem;
        // background-color: $element;
        margin-right: .5rem;
      }

    }
  }
  .header-btn{
    display: flex;
    align-items: center;
    justify-content: center;
    // padding: 0 .5rem;
    height: 2rem;
    border-radius: .5rem;
    transition: var(--animation-duration);
    cursor: pointer;

    &:hover{
      background-color: $element;
      background-color: rgba($color: #000000, $alpha: 0.06);
    }
  }
}

@media (max-width: 768px) {
  .header-wrapper{
    padding: .5rem .25rem;
  }
}